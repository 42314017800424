import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";

// styles
import "./src/assets/css/bootstrap.min.css";
import "./src/assets/scss/now-ui-kit.scss?v=1.4.0";
import "./src/assets/demo/demo.css?v=1.4.0";
import "./src/assets/demo/react-demo.css?v=1.4.0";
import "./src/assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "react-datepicker/dist/react-datepicker.css";
import './src/assets/scss/index.scss';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
