exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-enquiry-tsx": () => import("./../../../src/pages/enquiry.tsx" /* webpackChunkName: "component---src-pages-enquiry-tsx" */),
  "component---src-pages-heatpump-tsx": () => import("./../../../src/pages/heatpump.tsx" /* webpackChunkName: "component---src-pages-heatpump-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-main-services-tsx": () => import("./../../../src/pages/main-services.tsx" /* webpackChunkName: "component---src-pages-main-services-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-sub-services-tsx": () => import("./../../../src/pages/sub-services.tsx" /* webpackChunkName: "component---src-pages-sub-services-tsx" */)
}

